import Vuex from 'vuex';

export default new Vuex.Store({
    state: {
        appQueue: 0,
        userData: {},
        language: {},
    },
    mutations: {
        addToAppQueue(state) {
            state.appQueue++;
        },
        removeFromAppQueue(state) {
            state.appQueue--;
        },
        setUserData(state, data) {
            state.userData = data;
        },
        setUserAvatar(state, data) {
            state.userData.avatar = data;
        },
        setLanguageData(state, data) {
            state.language = data;
        }
    },
    getters : {
        getUserData(state) {
            return state.userData;
        },
        getLanguageData(state) {
            return state.language;
        },
        getAppQueue(state) {
            return state.appQueue;
        }
    }

});
